import axios from 'axios';
const http = axios.create({
  baseURL: 'https://okumaplatformu.com',
  headers: {
    'Content-type': 'application/json',
  },
});

class ContactService {
  sendContactMessage(data) {
    return http.post('/api/email/contact-us', data);
  }
}

export default new ContactService();
