import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  User,
  EnvelopeSimple,
  Phone,
  MapPin,
  GitBranch,
  Suitcase,
  Fish,
} from 'phosphor-react';

import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import FooterBlack from 'components/Custom/FooterBlack';
import ContactService from 'services/ContactService';

function ContactPage() {
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [numberFocus, setNumberFocus] = useState(false);

  const [rod, setRod] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.body.classList.add('contact-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  return (
    <>
      {/* <CustomAlert />
      <CustomLoading /> */}
      <DropdownFixedNavbar />
      <div className="wrapper">
        <div className="section-space" />
        <div className="section">
          <div className="main">
            <div className="contact-content">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="5">
                    <h2 className="title">İLETİŞİM</h2>
                    <p className="description">
                      Ürünlerimizle ilgili her konuda bizimle iletişime
                      geçebilirsiniz. En kısa sürede sizlerle temas kuracağız.
                      <br></br>
                      <br></br>
                    </p>
                    <Form
                      id="contact-form"
                      method="post"
                      role="form"
                      className="contact-form"
                    >
                      <label>
                        İsim
                        <span> *</span>
                      </label>
                      <InputGroup style={{ display: 'none' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Fish color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="Rod"
                          autoComplete="Rod"
                          placeholder="Fish Rod"
                          type="text"
                          value={rod}
                          onChange={(e) => setRod(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={nameFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <User color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="İsim"
                          autoComplete="name"
                          placeholder="İsim"
                          type="text"
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>
                        E-posta
                        <span> *</span>
                      </label>
                      <InputGroup
                        className={emailFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <EnvelopeSimple color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="E-posta"
                          autoComplete="email"
                          placeholder="E-posta"
                          type="email"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>Telefon No</label>
                      <InputGroup
                        className={numberFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Phone color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="number"
                          placeholder="Telefon No"
                          type="text"
                          onFocus={() => setNumberFocus(true)}
                          onBlur={() => setNumberFocus(false)}
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <FormGroup>
                        <label>
                          Mesaj
                          <span> *</span>
                        </label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          placeholder="Mesaj"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></Input>
                      </FormGroup>
                      <div className="submit text-center">
                        <Button
                          className="btn-raised btn-round"
                          color="info"
                          defaultValue="Gönder"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            try {
                              if (rod === '') {
                                const response =
                                  await ContactService.sendContactMessage({
                                    name,
                                    email,
                                    phone: number,
                                    message:
                                      'Message From Kazanırım Contact Page: \n' +
                                      message,
                                  });

                                console.log(response);

                                if (
                                  response.status === 200 &&
                                  response.data.status === 'success'
                                ) {
                                  await Swal.fire({
                                    icon: 'success',
                                    title: 'BAŞARILI!',
                                    text: 'Mesajınız başarıyla gönderildi. En kısa sürede sizinle iletişime geçeceğiz.',
                                    showCancelButton: false,
                                    confirmButtonText: 'Tamam',
                                    confirmButtonColor: '#3BA8FF',
                                  });
                                }
                              }
                            } catch (error) {
                              await Swal.fire({
                                icon: 'error',
                                title: 'HATA!',
                                text: 'Mesajınız gönderilirken bir hata oluştu. Lütfen tekrar deneyiniz.',
                                showCancelButton: false,
                                confirmButtonText: 'Tamam',
                                confirmButtonColor: '#3BA8FF',
                              });
                            } finally {
                              // Reset fields
                              setRod('');
                              setName('');
                              setEmail('');
                              setNumber('');
                              setMessage('');
                            }
                          }}
                        >
                          Gönder
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col className="ml-auto mr-auto" md="5">
                    <div className="info info-horizontal mt-5">
                      <div className="icon icon-info">
                        <MapPin size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">Merkez Ofis</h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          Edict Digital Bilgi Teknolojileri A.Ş. <br /> Kavacık
                          Mah. Gülşen Sk. No: 12/3 <br /> Beykoz / İSTANBUL
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal">
                      <div className="icon icon-info">
                        <GitBranch size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">Şube (AR-GE)</h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          Malatya Teknokent
                          <br /> İnönü Üniversitesi Karşısı <br />
                          Battalgazi / MALATYA
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal">
                      <div className="icon icon-info">
                        <Suitcase size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">Şirket Bilgileri</h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          Edict Digital Bilgi Teknolojileri A.Ş. <br />
                          Beykoz VD · 324 096 4833
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-5" md="12">
                    <p>
                      Destek ve talepleriniz için lütfen{' '}
                      <a
                        href="mailto: info@edictdigital.com"
                        style={{ fontWeight: 'bolder' }}
                      >
                        info@edictdigital.com
                      </a>{' '}
                      e-posta adresi üzerinden iletişime geçiniz.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

export default ContactPage;
