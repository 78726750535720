import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { NavbarBrand, Navbar, Container } from 'reactstrap';

const CustomNavbar = ({
  collapseOpen,
  setCollapseOpen,
  navbarColor,
  logoRef,
  white,
}) => {
  useEffect(() => {
    if (white) {
      logoRef.current.src = require('assets/img/logo-white.png').default;
    } else {
      logoRef.current.src = require('assets/img/logo.png').default;
    }
  }, [logoRef, white]);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={'fixed-top' + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                className="cr-logo"
                ref={logoRef}
                src={require('assets/img/logo-white.png').default}
                alt="Okuma Platformu Logo"
              />
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
