import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Kvkk from 'views/Kvkk';
import KvkkAndroid from 'views/KvkkAndroid';
import TermsOfUse from 'views/TermsOfUse';
import Home from 'views/Home';
import ContactPage from 'views/ContactPage';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/contact" render={(props) => <ContactPage {...props} />} />
        <Route path="/kvkk" render={(props) => <Kvkk {...props} />} />
        <Route
          path="/kvkk-android"
          render={(props) => <KvkkAndroid {...props} />}
        />
        <Route
          path="/terms-of-use"
          render={(props) => <TermsOfUse {...props} />}
        />

        <Route exact path="/" render={(props) => <Home {...props} />} />

        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
