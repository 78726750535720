import React from 'react';
import { Container, Col, Row } from 'reactstrap';

import DropdownFixedBlurNavbar from 'components/Custom/DropdownFixedBlurNavbar';
import RoundedCard from './home-components/RoundedCard';
import FooterBlack from 'components/Custom/FooterBlack';
import playstoreImage from 'assets/img/playstore-badge.png';
import appstoreImage from 'assets/img/appstore-badge.png';

const Home = () => {
  return (
    <>
      <DropdownFixedBlurNavbar />
      <div className="wrapper" style={{ backgroundColor: '#F5F5F7' }}>
        <div className="section-intro">
          <div className="badges-container">
            <div className="badges">
              <p className="badges-text">Kazanırım LGS Matematik</p>
              <div className="badges-images">
                <a
                  href="https://apps.apple.com/tr/app/kazanırım-lgs-matematik/id6447350385"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstoreImage} alt="Appstore Imaj" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.edictdigital.kazanirimmatlgs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstoreImage} alt="Playstore Imaj" />
                </a>
              </div>
            </div>
          </div>

          <div className="intro-image-container">
            <div className="intro-pc">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/lgs_matematik.png"
                alt="LGS Matematik"
              />
            </div>
          </div>
        </div>

        <div className="section-intro-text">
          <Container>
            <Row>
              <Col sm={12} lg={{ span: 10 }}>
                <Row></Row>
                <Row>
                  <Col>
                    <h2 className="intro-text-title">
                      7/24 Erişilebilir
                      <br />
                      Çevrim içi
                      <br />
                      Sınava Hazırlık
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} />
                  <Col sm={12} lg={6}>
                    <div>
                      <p className="intro-text-description">
                        LGS Matematik kapsamında 9000+ soru, 4000+ yeni nesil
                        soru, 150+ konu anlatım / soru çözüm videoları, canlı
                        dersler ve denemeler ile sınava hazırlıkta en büyük
                        yardımcınız.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Kullanıcı Dostu Tasarım
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12}>
                <RoundedCard
                  title="Kullanıcı dostu arayüz tasarımları ile soru çözme deneyimi."
                  titleColor="white"
                  subtitle=""
                  subtitleColor="rgb(29, 29, 31)"
                  background="#DE9138"
                  //background="linear-gradient(to top left, #ECC291, #D98524)"
                >
                  <div
                    style={{
                      paddingTop: '70px',
                    }}
                  >
                    <div className="home-rounded-card-image">
                      <img
                        src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/lgs_matematik_hazirlik_app.png"
                        alt="imaj"
                      />
                    </div>
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Soru Çözme
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="9000+ Soru"
                  titleColor="#000000"
                  subtitle="Kazanırım uygulaması ile ister tek tek, ister konu bazlı, ister deneme olarak farklı kategorilerde soru çözülebilir."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '85%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/lgs_rapor_istatistik.png"
                      alt="Kazanırım ana menü"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={8}>
                <RoundedCard
                  titleColor="#FFFFFF"
                  subtitleColor="#FFFFFF"
                  background="#82A1BB"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="home-rounded-card-text">
                      <p>
                        Farklı kategorilerde soru çözme fırsatı ile güçlü
                        hazırlık
                      </p>
                    </div>
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Yeni Nesil Sorular
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8}>
                <RoundedCard background="#EFC601">
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/soru.png"
                      alt="soru"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={4}>
                <RoundedCard background="white">
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      //paddingLeft: '50px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="home-rounded-card-text">
                      <p
                        style={{
                          color: '#000000',
                        }}
                      >
                        Yeni nesil ağırlıklı sorular ve çözümleri.
                      </p>
                    </div>
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Videolu Anlatım
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <RoundedCard
                  background="white"
                  title="Konu Anlatım Videoları"
                  titleColor="#000000"
                  subtitle="Sınav kapsamındaki konuların videolu konu anlatımları ve örnek soru çözümleri."
                  subtitleColor="#000000"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      paddingTop: '50px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/konu_anlatim.png"
                      alt="soru"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={6}>
                <RoundedCard
                  background="#82BBA0"
                  title="Soru Çözüm Videoları"
                  titleColor="white"
                  subtitle="Sınav kapsamındaki konularla ilgili örnek soru çözüm videoları."
                  subtitleColor="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      paddingTop: '10px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '85%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/soru_cozum.png"
                      alt="soru"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Deneme Sınavları
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12}>
                <RoundedCard
                  title="Kişiye özel ve ortak deneme sınavları ile hazırlan."
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="#82A1BB"
                >
                  <div
                    className="home-rounded-card-image"
                    style={{
                      paddingLeft: '40px',
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/lgs_deneme_sinavi.png"
                      alt="deneme_sinavi"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#000000',
                    }}
                  >
                    Raporlar
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12}>
                <RoundedCard
                  title="Raporlar ile gelişimini takip et."
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="#DE9138"
                >
                  <div
                    className="home-rounded-card-image"
                    style={{
                      paddingLeft: '40px',
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/kazanirim/lgs_matematik_ders.png"
                      alt="lgs matematik ders raporları"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          style={{
            paddingTop: '70px',
          }}
        />

        <FooterBlack />
      </div>
    </>
  );
};

export default Home;
