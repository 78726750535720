import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  NavbarBrand,
  Navbar,
  Container,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

const CustomNavbarBlur = ({
  collapseOpen,
  setCollapseOpen,
  navbarColor,
  logoRef,
  white,
}) => {
  useEffect(() => {
    if (white) {
      logoRef.current.src = require('assets/img/logo-white.png').default;
    } else {
      logoRef.current.src = require('assets/img/logo.png').default;
    }
  }, [logoRef, white]);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={'no-shadow fixed-top' + navbarColor}
        color="transparent"
        expand="lg"
        position="relative"
      >
        <div
          style={{
            position: 'absolute',
            marginLeft: '-16px',
            top: 0,
            backgroundColor: 'rgba(245, 245, 247, 0.72)',
            backdropFilter: 'saturate(180%) blur(20px)',
            width: '100%',
            height: '100%',
            zIndex: -1,
            // filter: 'blur(4px)',
          }}
        ></div>
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                className="cr-logo"
                ref={logoRef}
                src={require('assets/img/logo-white.png').default}
                alt="Okuma Platformu Logo"
              />
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <NavLink href="/contact">
                  <p style={{ color: '#000000' }}>İLETİŞİM</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbarBlur;
